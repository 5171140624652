.popup-content { 
	padding: 20px;
    border-radius: 5px;
    border-radius: 16px; 
    background: linear-gradient(312deg, rgba(111,47,130,1) 30%, rgba(107,45,127,1) 33%, rgba(51,24,83,1) 75%);
    display: inline-flex;
    padding: 16px 40px 40px 40px;
    flex-direction: column;
    align-items: center;
    gap: 8px;
	width: 33%;
    z-index: 9999999999;
}
.popup-content-box {
    display: flex;
    align-content: space-around;
    justify-content: center;
    align-items: center;
	padding: 5px 0 100px;
}
.hero-section-single.thanksPage {
    background-position: calc(80% - 0px) 40%;
    background-size: cover;
    z-index: 1;
    width: 100%;
    background: linear-gradient(144deg, rgba(1,0,15,1) 26%, rgba(31,1,67,1) 38%, rgba(87,21,112,1) 64%, rgba(87,21,112,1) 53%, rgba(105,44,119,1) 70%, rgba(123,107,124,1) 91%);
}
.footer-popup{
	display:none;
}
@media only screen and (max-width: 1400px) {
.popup-content {
    width: 39%;
}}
@media only screen and (max-width: 915px) {
.popup-content {
    width: 70%;
}
}

@media only screen and (max-width: 480px) {
.footer-popup{
display:block;
}
img.Illustration {
    width: 268px;
    height: 285px;
}
.popup-content {
    width: 90%;
	    padding: 16px 20px 40px 20px;
}
}